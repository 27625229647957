import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config";
import { handleUnauthorized } from "../utils/authUtils";
import {
  Box,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Link,
  Flex,
  VStack,
  HStack,
  Center,
  Badge,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { CheckIcon, ArrowForwardIcon } from "@chakra-ui/icons";

function Home() {
  const [lessons, setLessons] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    days_practiced: 0,
    activities_completed: 0,
    lessons_completed: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState("All levels");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const [lessonsResponse, userDataResponse, userProgressResponse] =
        await Promise.all([
          axios.get(`${config.API_URL}/lessons?level=${selectedLevel}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${config.API_URL}/user-profile`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${config.API_URL}/user/progress`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

      const lessonsWithCompletion = lessonsResponse.data.map((lesson) => ({
        ...lesson,
        completed: userProgressResponse.data.lessons_progress.some(
          (progress) => progress.lesson_id === lesson.id && progress.completed
        ),
      }));

      setLessons(lessonsWithCompletion);
      setUserData(userDataResponse.data);
      setIsLoading(false);
    } catch (err) {
      handleUnauthorized(err, navigate);
      setError("Failed to fetch data. Please try again later.");
      setIsLoading(false);
    }
  }, [selectedLevel, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLevelSelect = (level) => {
    setSelectedLevel(level);
  };

  return (
    <Box maxWidth="1050px" margin="auto" padding={8}>
      <Center>
        <Heading mb={4} size="2xl" textAlign="center">
          Ciao {userData.name}!
        </Heading>
      </Center>

      <Text mb={8} textAlign="center" fontSize="lg">
        Select a lesson from the list below and get started with today's
        practice!
      </Text>

      <Center mb={8}>
        <SimpleGrid columns={[1, 2, 3]} spacing={[5, 10]} textAlign="center">
          <Box>
            <Heading size="2xl">{userData.days_practiced}</Heading>
            <Text fontSize="lg">Days practiced</Text>
          </Box>
          <Box>
            <Heading size="2xl">{userData.activities_completed}</Heading>
            <Text fontSize="lg">Activities completed</Text>
          </Box>
          <Box>
            <Heading size="2xl">{userData.lessons_completed}</Heading>
            <Text fontSize="lg">Lessons completed</Text>
          </Box>
        </SimpleGrid>
      </Center>

      {/* <Center mb={8}>
        <Button colorScheme="blue" size="lg" fontSize="xl" padding={6}>
          Learn Now
        </Button>
      </Center> */}

      <VStack spacing={4} align="center" mb={8}>
        <Heading
          size="lg"
          textAlign="center"
          mt={6}
          mb={4}
          bg="blue.100"
          p={2}
          borderRadius="md"
        >
          Pick a lesson to practice
        </Heading>

        <Link
          as={RouterLink}
          to="/phrase-bank"
          fontSize="xl"
          fontWeight="bold"
          color="blue.500"
          mb={4}
          _hover={{ textDecoration: "none", color: "blue.600" }}
        >
          Or, go to your Phrase Bank <ArrowForwardIcon />
        </Link>

        {/* New Callout Box */}
        <Box
          w="100%"
          p={4}
          bg="blue.50"
          borderRadius="md"
          border="1px"
          borderColor="blue.200"
          mb={4}
        >
          <HStack spacing={3} align="center">
            <Text fontSize="lg" color="red.500" fontWeight="bold">
              NEW
            </Text>
            <Text fontSize="lg">for ItalianChat Students...</Text>
          </HStack>
          <Link
            href="https://speak.learnitalianpod.com/translate-now"
            target="_blank"
            display="block"
            mt={2}
            color="blue.500"
            fontSize="lg"
            fontWeight="semibold"
            _hover={{ textDecoration: "none", color: "blue.600" }}
          >
            Italian Pronunciation & Phonetics Tool <ArrowForwardIcon />
          </Link>
          <Text fontSize="md" color="gray.600" mt={1}>
            See exactly how words break down phonetically, hear them spoken,
            then practice. Perfect for visual learners: get phonetic guides plus
            instant audio for any Italian phrase.
          </Text>
        </Box>

        <SimpleGrid columns={[1, 2, 4]} spacing={4} width="100%">
          <Button
            variant={selectedLevel === "All levels" ? "solid" : "outline"}
            onClick={() => handleLevelSelect("All levels")}
          >
            All levels
          </Button>
          <Button
            variant={selectedLevel === "Novice" ? "solid" : "outline"}
            onClick={() => handleLevelSelect("Novice")}
          >
            Complete beginner (A1)
          </Button>
          <Button
            variant={selectedLevel === "Beginner" ? "solid" : "outline"}
            onClick={() => handleLevelSelect("Beginner")}
          >
            Beginner (A2)
          </Button>
          <Button
            variant={selectedLevel === "Intermediate" ? "solid" : "outline"}
            onClick={() => handleLevelSelect("Intermediate")}
          >
            Intermediate (B1)
          </Button>
        </SimpleGrid>
        <Link
          fontSize="sm"
          color="blue.500"
          onClick={onOpen}
          textAlign="center"
          display="block"
          mt={2}
        >
          What do A1, A2, and B1 mean?
        </Link>
      </VStack>

      <SimpleGrid columns={[1, null, 2]} spacing={4}>
        {isLoading ? (
          <Text>Loading lessons...</Text>
        ) : error ? (
          <Text color="red.500">{error}</Text>
        ) : (
          lessons.map((lesson) => (
            <Box
              key={lesson.id}
              p={4}
              borderWidth={1}
              borderRadius="md"
              position="relative"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center">
                  <Badge colorScheme="blue" fontSize="sm" mr={2}>
                    Lesson {lesson.lesson_number}
                  </Badge>
                  <Link
                    as={RouterLink}
                    to={`/lessons/${lesson.id}`}
                    color="blue.500"
                  >
                    {lesson.title}
                  </Link>
                </Flex>
                {lesson.completed && <Icon as={CheckIcon} color="green.500" />}
              </Flex>
            </Box>
          ))
        )}
      </SimpleGrid>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>CEFR Language Levels</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              ItalianChat follows the Common European Framework of Reference for
              Languages (CEFR) guidelines, ensuring a structured and
              internationally recognized approach to language learning.
            </Text>
            <VStack align="start" spacing={4}>
              <Box>
                <Heading size="sm" mb={2}>
                  A1 - Complete Beginner
                </Heading>
                <Text>
                  Understand and use basic expressions for concrete needs.
                  Introduce yourself and interact simply.
                </Text>
              </Box>
              <Box>
                <Heading size="sm" mb={2}>
                  A2 - Elementary
                </Heading>
                <Text>
                  Communicate in simple, routine tasks. Describe your background
                  and immediate environment.
                </Text>
              </Box>
              <Box>
                <Heading size="sm" mb={2}>
                  B1 - Intermediate
                </Heading>
                <Text>
                  Understand main points of clear standard input on familiar
                  matters. Produce simple connected texts on familiar topics.
                </Text>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Home;
