import React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Link, Icon } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";

const BackToLessonLink = () => {
  const { lessonId } = useParams();

  return (
    <Link
      as={RouterLink}
      to={`/lessons/${lessonId}`}
      display="inline-flex"
      alignItems="center"
      mb={4}
      color="blue.500"
      fontWeight="medium"
    >
      <Icon as={ChevronLeftIcon} mr={1} />
      Back to Lesson Main Page
    </Link>
  );
};

export default BackToLessonLink;
