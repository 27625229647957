import React, { useState } from "react";
import axios from "axios";
import config from "../../config";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Alert,
  AlertIcon,
  FormErrorMessage,
} from "@chakra-ui/react";

const RequestPasswordResetSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const RequestPasswordReset = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("info");

  const handleSubmit = async (values, { setSubmitting }) => {
    setShowAlert(false);

    try {
      const response = await axios.post(
        `${config.API_URL}/request-password-reset`,
        values
      );
      setAlertMessage(response.data.message);
      setAlertStatus("success");
      setShowAlert(true);
    } catch (error) {
      setAlertMessage(error.response?.data?.message || "An error occurred");
      setAlertStatus("error");
      setShowAlert(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box maxWidth="420px" margin="auto" mt={8} px={4}>
      <VStack spacing={4} align="stretch">
        <Heading>Reset Password</Heading>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={RequestPasswordResetSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Field name="email">
                {({ field }) => (
                  <FormControl isInvalid={errors.email && touched.email}>
                    <FormLabel>Email</FormLabel>
                    <Input {...field} type="email" />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {showAlert && (
                <Alert status={alertStatus} mt={4}>
                  <AlertIcon />
                  {alertMessage}
                </Alert>
              )}

              <Button
                type="submit"
                colorScheme="green"
                width="full"
                mt={4}
                isLoading={isSubmitting}
              >
                Request Password Reset
              </Button>
            </Form>
          )}
        </Formik>
        <Text mt={4}>
          Know your password? <a href="/login">Log in</a>.
        </Text>
      </VStack>
    </Box>
  );
};

export default RequestPasswordReset;
