import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";

const EmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await axios.get(`${config.API_URL}/verify-email/${token}`);
        navigate("/plan-selection");
      } catch (err) {
        setError("Email verification failed. Please try again.");
      } finally {
        setVerifying(false);
      }
    };
    verifyEmail();
  }, [token, navigate]);

  if (verifying) return <div>Verifying your email...</div>;
  if (error) return <div>{error}</div>;
  return null;
};

export default EmailVerification;
