import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import config from "../config";
import { handleUnauthorized } from "../utils/authUtils";
import HowToPracticeModal from "../components/HowToPracticeModal";
import {
  Box,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SimpleGrid,
  Button,
  Link,
  Text,
  VStack,
  Icon,
  Grid,
  GridItem,
  useBreakpointValue,
  Center,
  Flex,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FaPlay,
  FaPause,
  FaBook,
  FaHeadphones,
  FaMicrophone,
  FaQuestionCircle,
  FaComments,
  FaForward,
  FaBackward,
} from "react-icons/fa";
import { CheckIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import BackToDashboardLink from "../components/BackToDashboardLink";

function Lesson() {
  const { lessonId } = useParams();
  const [completed, setCompleted] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPlayingNormal, setIsPlayingNormal] = useState(false);
  const [hasStartedNormal, setHasStartedNormal] = useState(false);
  const [isPlayingSlow, setIsPlayingSlow] = useState(false);
  const [hasStartedSlow, setHasStartedSlow] = useState(false);
  const normalAudioRef = useRef(new Audio());
  const slowAudioRef = useRef(new Audio());
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchLessonData = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const [lessonResponse, progressResponse] = await Promise.all([
        axios.get(`${config.API_URL}/lessons/${lessonId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.API_URL}/user/progress`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setLessonData(lessonResponse.data);
      const lessonProgress = progressResponse.data.lessons_progress.find(
        (progress) => progress.lesson_id === parseInt(lessonId)
      );
      setCompleted(lessonProgress ? lessonProgress.completed : false);
      setIsLoading(false);
    } catch (err) {
      handleUnauthorized(err, navigate);
      setError("Failed to fetch lesson data. Please try again later.");
      setIsLoading(false);
    }
  }, [lessonId, navigate]);

  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  useEffect(() => {
    const normalAudio = normalAudioRef.current;
    const slowAudio = slowAudioRef.current;

    const handleNormalEnded = () => {
      setIsPlayingNormal(false);
      setHasStartedNormal(false);
    };

    const handleSlowEnded = () => {
      setIsPlayingSlow(false);
      setHasStartedSlow(false);
    };

    normalAudio.addEventListener("ended", handleNormalEnded);
    slowAudio.addEventListener("ended", handleSlowEnded);

    return () => {
      normalAudio.removeEventListener("ended", handleNormalEnded);
      slowAudio.removeEventListener("ended", handleSlowEnded);
    };
  }, []);

  const activities = [
    {
      id: "reading-listening",
      title: "1. Read & Listen",
      description: "Explore the dialogue with text and audio.",
      icon: FaHeadphones,
    },
    {
      id: "translation",
      title: "2. Translation",
      description: "Practice translating phrases from English to Italian.",
      icon: FaBook,
    },
    {
      id: "pronunciation",
      title: "3. Pronunciation",
      description: "Improve your Italian pronunciation with AI feedback.",
      icon: FaMicrophone,
    },
    {
      id: "lesson-quiz",
      title: "4. Lesson Quiz",
      description: "Test your understanding of the dialogue.",
      icon: FaQuestionCircle,
    },
    {
      id: "live-chat",
      title: "5. Chat Simulation",
      description: "Simulate a real conversation by filling in the blanks.",
      icon: FaComments,
    },
  ];

  const playFullConversation = (speed) => {
    const audio =
      speed === "normal" ? normalAudioRef.current : slowAudioRef.current;
    const isPlayingCurrent =
      speed === "normal" ? isPlayingNormal : isPlayingSlow;
    const setIsPlayingCurrent =
      speed === "normal" ? setIsPlayingNormal : setIsPlayingSlow;
    const hasStartedCurrent =
      speed === "normal" ? hasStartedNormal : hasStartedSlow;
    const setHasStartedCurrent =
      speed === "normal" ? setHasStartedNormal : setHasStartedSlow;

    if (isPlayingCurrent) {
      audio.pause();
    } else {
      if (!hasStartedCurrent) {
        audio.src =
          speed === "normal" ? lessonData.audio_url : lessonData.audio_slow_url;
      }
      audio.currentTime = 0;
      audio
        .play()
        .catch((error) => console.error("Error playing audio:", error));
    }
    setIsPlayingCurrent(!isPlayingCurrent);
    setHasStartedCurrent(true);
  };

  const getFullConversationButtonText = (speed) => {
    const hasStarted = speed === "normal" ? hasStartedNormal : hasStartedSlow;
    const isPlaying = speed === "normal" ? isPlayingNormal : isPlayingSlow;
    if (!hasStarted) {
      return speed === "normal"
        ? "Regular Speed Conversation"
        : "Slow Speed Conversation";
    }
    return isPlaying ? "Pause" : "Play";
  };

  const toggleLessonCompletion = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${config.API_URL}/lessons/${lessonId}/toggle`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCompleted(response.data.completed);
      toast({
        title: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error toggling lesson completion:", error);
      toast({
        title: "Error",
        description: "Failed to update lesson status. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Use responsive layout
  const gridTemplateColumns = useBreakpointValue({
    base: "1fr", // Mobile: single column
    md: "1fr 1fr", // Desktop: two columns
  });

  if (isLoading) {
    return <Center>Loading lesson data...</Center>;
  }

  if (error) {
    return <Center color="red.500">{error}</Center>;
  }

  if (!lessonData) {
    return <Center>No lesson data available.</Center>;
  }

  return (
    <Box maxWidth="1050px" margin="auto" padding={8}>
      <BackToDashboardLink />
      <Center>
        <VStack spacing={4}>
          <Heading textAlign="center">
            Lesson {lessonData.lesson_number}: {lessonData.title}
          </Heading>
        </VStack>
      </Center>
      <Link
        color="blue.500"
        onClick={onOpen}
        mt={6}
        mb={4}
        display="block"
        textAlign="center"
        fontSize="lg"
      >
        <InfoOutlineIcon /> Watch: How to Practice a Lesson
      </Link>
      <HowToPracticeModal isOpen={isOpen} onClose={onClose} />
      <Center>
        <Heading size="lg" my={6}>
          Lesson Activities
        </Heading>
      </Center>

      <SimpleGrid columns={[1, 2, 3, 5]} spacing={6} mb={8}>
        {activities.map((activity) => (
          <Link
            as={RouterLink}
            to={`/lessons/${lessonId}/${activity.id}`}
            key={activity.id}
            _hover={{ textDecoration: "none" }}
          >
            <Box
              borderWidth={1}
              borderRadius="lg"
              p={4}
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              transition="all 0.2s"
              _hover={{ transform: "scale(1.05)", boxShadow: "lg" }}
            >
              <VStack align="start" spacing={2}>
                <Icon as={activity.icon} boxSize={8} color="blue.500" />
                <Text fontWeight="bold">{activity.title}</Text>
                <Text fontSize="sm">{activity.description}</Text>
              </VStack>
            </Box>
          </Link>
        ))}
      </SimpleGrid>
      <Heading size="md" mb={4}>
        Conversation & Annotations
      </Heading>
      <Tabs>
        <TabList>
          <Tab>Full Conversation & Transcript</Tab>
          <Tab>Annotations</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack spacing={4} width="100%" my={4}>
              <Text fontSize="lg" fontWeight="semibold" mb={2}>
                Familiarize yourself with the lesson dialogue:
              </Text>
              <Text mb={4}>
                Choose a speed to play the full conversation. Follow along with
                the line-by-line transcript and translation below. Don't forget
                to check the Annotations tab for additional insights into the
                conversation.
              </Text>
              <Flex
                direction={{ base: "column", md: "row" }}
                width="100%"
                gap={4}
              >
                <Button
                  leftIcon={<Icon as={isPlayingNormal ? FaPause : FaPlay} />}
                  rightIcon={<Icon as={FaForward} />}
                  onClick={() => playFullConversation("normal")}
                  colorScheme="blue"
                  width="100%"
                >
                  {getFullConversationButtonText("normal")}
                </Button>
                <Button
                  leftIcon={<Icon as={isPlayingSlow ? FaPause : FaPlay} />}
                  rightIcon={<Icon as={FaBackward} />}
                  onClick={() => playFullConversation("slow")}
                  colorScheme="green"
                  width="100%"
                >
                  {getFullConversationButtonText("slow")}
                </Button>
              </Flex>
            </VStack>

            <Box my={6} borderBottomWidth="2px" borderColor="gray.200" />
            <Heading size="md" mb={4}>
              Line by Line Transcript and Translation
            </Heading>

            <Grid templateColumns={gridTemplateColumns} gap={4}>
              {lessonData.translation.map((line, index) => (
                <React.Fragment key={index}>
                  <GridItem bg="blue.100" p={2} borderRadius="md">
                    <Text fontWeight="bold">{line.italian}</Text>
                  </GridItem>
                  <GridItem bg="gray.100" p={2} borderRadius="md">
                    <Text>{line.english}</Text>
                  </GridItem>
                </React.Fragment>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel>
            <Text
              dangerouslySetInnerHTML={{ __html: lessonData.explanation }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box mt={8} mb={2} p={4} bg="blue.50" borderRadius="md">
        <Text fontSize="sm" textAlign="center">
          <strong>Tip:</strong> For the best learning experience, aim to
          complete all activities before marking the lesson as complete.
        </Text>
      </Box>
      <Flex justifyContent="center">
        <Button
          rightIcon={<CheckIcon />}
          colorScheme={completed ? "green" : "gray"}
          mt={4}
          onClick={toggleLessonCompletion}
        >
          {completed ? "Lesson Completed" : "Mark Lesson as Complete"}
        </Button>
      </Flex>
    </Box>
  );
}

export default Lesson;
