import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import config from "../../config";
import {
  Box,
  VStack,
  HStack,
  Heading,
  Text,
  IconButton,
  Badge,
  useToast,
  Progress,
  Flex,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { FaPlay } from "react-icons/fa";
import BackToDashboardLink from "../../components/BackToDashboardLink";

function PhraseBank() {
  const [phrases, setPhrases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const toast = useToast();
  const audioRef = useRef(new Audio());

  const fetchPhrases = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${config.API_URL}/phrase-bank`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setPhrases(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching phrases:", error);
      setError("Failed to fetch phrases. Please try again later.");
      setIsLoading(false);
      toast({
        title: "Error fetching phrases",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchPhrases();
  }, [fetchPhrases]);

  const deletePhrase = async (phraseId) => {
    try {
      await axios.delete(`${config.API_URL}/phrase-bank/${phraseId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setPhrases(phrases.filter((phrase) => phrase.id !== phraseId));

      // Update localStorage
      const deletedPhrase = phrases.find((p) => p.id === phraseId);
      if (deletedPhrase) {
        const lessonId = deletedPhrase.lesson_id;
        const storedPhrases = JSON.parse(
          localStorage.getItem(`addedPhrases_${lessonId}`) || "[]"
        );
        const updatedPhrases = storedPhrases.filter(
          (index) => index !== deletedPhrase.index
        );
        localStorage.setItem(
          `addedPhrases_${lessonId}`,
          JSON.stringify(updatedPhrases)
        );
      }

      toast({
        title: "Phrase deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting phrase:", error);
      toast({
        title: "Error deleting phrase",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const playAudio = (audioUrl, phraseId) => {
    const audio = audioRef.current;

    if (currentlyPlaying === phraseId) {
      audio.pause();
      setCurrentlyPlaying(null);
    } else {
      audio.src = audioUrl;
      audio
        .play()
        .catch((error) => console.error("Error playing audio:", error));
      setCurrentlyPlaying(phraseId);
    }
  };

  if (isLoading) return <Progress size="xs" isIndeterminate />;
  if (error) return <Box color="red.500">{error}</Box>;

  return (
    <Box maxWidth="1050px" margin="auto" padding={8}>
      <BackToDashboardLink />
      <VStack spacing={6} align="stretch">
        <HStack justifyContent="space-between" flexWrap="wrap">
          <Heading size="lg">Phrase Bank</Heading>
          <Badge colorScheme="blue" p={2} borderRadius="md">
            {phrases.length} phrases
          </Badge>
        </HStack>

        <Text>
          Review and practice your saved phrases. Listen to the audio, practice
          pronunciation, and remove phrases you've mastered.
        </Text>

        <VStack align="stretch" spacing={4}>
          {phrases.map((phrase, index) => (
            <Flex
              key={phrase.id}
              bg={index % 2 === 0 ? "gray.50" : "gray.100"}
              p={4}
              borderRadius="md"
              alignItems="center"
              flexDirection={{ base: "column", md: "row" }}
              gap={4}
            >
              <IconButton
                icon={<FaPlay />}
                onClick={() => playAudio(phrase.audio_url, phrase.id)}
                aria-label={currentlyPlaying === phrase.id ? "Pause" : "Play"}
                colorScheme="blue"
                size="sm"
              />
              <VStack align="stretch" flex={1}>
                <Text fontWeight="bold">{phrase.italian}</Text>
                <Text>{phrase.english}</Text>
              </VStack>
              <IconButton
                icon={<DeleteIcon />}
                onClick={() => deletePhrase(phrase.id)}
                aria-label="Delete phrase"
                colorScheme="red"
                size="sm"
              />
            </Flex>
          ))}
        </VStack>

        {phrases.length === 0 && (
          <Text textAlign="center" color="gray.500">
            Your Phrase Bank is empty. Add phrases from lessons to start
            building your collection.
          </Text>
        )}
      </VStack>
    </Box>
  );
}

export default PhraseBank;
