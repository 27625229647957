import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  HStack,
  Progress,
  Icon,
  Badge,
  Flex,
  Center,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import {
  FaPlay,
  FaMicrophone,
  // FaCheck,
  FaStop,
  FaArrowRight,
} from "react-icons/fa";
import { CheckIcon } from "@chakra-ui/icons";
import BackToLessonLink from "../../components/BackToLessonLink";

// CloudFront distribution URL
const CLOUDFRONT_URL = "https://damdyhcq5ngiz.cloudfront.net";

function PronunciationActivity() {
  const { lessonId } = useParams();
  const [lessonData, setLessonData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [recorded, setRecorded] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [score, setScore] = useState(null);
  const [completedLines, setCompletedLines] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [wordScores, setWordScores] = useState([]);
  const [wordsToPractice, setWordsToPractice] = useState([]);
  // const [feedback, setFeedback] = useState("");
  const [isGrading, setIsGrading] = useState(false);
  const [accuracyScore, setAccuracyScore] = useState(null);
  const [fluencyScore, setFluencyScore] = useState(null);
  const [completenessScore, setCompletenessScore] = useState(null);

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioRef = useRef(new Audio());

  const toast = useToast();

  const fetchLessonData = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const [lessonResponse, progressResponse] = await Promise.all([
        axios.get(`${config.API_URL}/lessons/${lessonId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.API_URL}/user/progress`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setLessonData(lessonResponse.data);
      const activityProgress = progressResponse.data.activities_progress.find(
        (progress) =>
          progress.lesson_id === parseInt(lessonId) &&
          progress.activity_type === "pronunciation"
      );
      setCompleted(activityProgress ? activityProgress.completed : false);
      setIsLoading(false);
    } catch (err) {
      setError("Failed to fetch lesson data. Please try again later.");
      setIsLoading(false);
    }
  }, [lessonId]);

  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  const playNativeAudio = () => {
    if (lessonData && lessonData.translation[currentLineIndex]) {
      audioRef.current.src = `${CLOUDFRONT_URL}/lesson_${lessonData.lesson_number}_${currentLineIndex}.mp3`;
      audioRef.current
        .play()
        .catch((error) => console.error("Error playing audio:", error));
    }
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        let mimeType = "audio/webm";

        // Check if the device is running iOS
        const isIOS =
          /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (isIOS) {
          mimeType = "audio/mp4";
        }

        // Check if the selected MIME type is supported
        if (MediaRecorder.isTypeSupported(mimeType)) {
          mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: mimeType,
          });
        } else {
          // Fallback to default MIME type if the selected one is not supported
          console.warn(
            `${mimeType} is not supported, using default MIME type.`
          );
          mediaRecorderRef.current = new MediaRecorder(stream);
        }

        audioChunksRef.current = [];
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: mediaRecorderRef.current.mimeType,
          });
          setAudioBlob(audioBlob);
          setRecorded(true);
          gradeRecording(audioBlob);
        };
        mediaRecorderRef.current.start();
        setIsRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        setError(
          "Failed to access microphone. Please check your browser settings."
        );
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const playRecordedAudio = () => {
    if (audioBlob) {
      const audio = new Audio();
      audio.src = URL.createObjectURL(audioBlob);
      audio
        .play()
        .catch((error) => console.error("Error playing audio:", error));
    }
  };

  const gradeRecording = async (audioBlob) => {
    setIsGrading(true);
    try {
      // Convert the blob to ArrayBuffer
      const arrayBuffer = await audioBlob.arrayBuffer();

      // Convert ArrayBuffer to WAV
      const wavBlob = await convertToWav(arrayBuffer);

      const formData = new FormData();
      formData.append("audio", wavBlob, "recording.wav");
      formData.append(
        "reference_text",
        lessonData.translation[currentLineIndex].italian
      );

      const gradingResponse = await axios.post(
        `${config.API_URL}/grade-pronunciation`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const gradingResult = gradingResponse.data;
      const pronunciationAssessment =
        gradingResult.NBest[0].PronunciationAssessment;

      setScore(pronunciationAssessment.PronScore);
      setAccuracyScore(pronunciationAssessment.AccuracyScore);
      setFluencyScore(pronunciationAssessment.FluencyScore);
      setCompletenessScore(pronunciationAssessment.CompletenessScore);
      setWordScores(
        gradingResult.NBest[0].Words.map((word) => ({
          word: word.Word,
          score: word.PronunciationAssessment.AccuracyScore,
        }))
      );
      setWordsToPractice(
        gradingResult.NBest[0].Words.filter(
          (word) => word.PronunciationAssessment.AccuracyScore < 80
        ).map((word) => ({
          word: word.Word,
          score: word.PronunciationAssessment.AccuracyScore,
        }))
      );
    } catch (error) {
      console.error("Error grading recording:", error);
      setError("Failed to grade recording. Please try again.");
    } finally {
      setIsGrading(false);
    }
  };

  // Helper function to convert ArrayBuffer to WAV
  const convertToWav = async (arrayBuffer) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    const wavFile = audioBufferToWav(audioBuffer);
    return new Blob([wavFile], { type: "audio/wav" });
  };

  // Function to convert AudioBuffer to WAV format
  function audioBufferToWav(buffer, opt) {
    opt = opt || {};

    var numChannels = buffer.numberOfChannels;
    var sampleRate = buffer.sampleRate;
    var format = opt.float32 ? 3 : 1;
    var bitDepth = format === 3 ? 32 : 16;

    var result;
    if (numChannels === 2) {
      result = interleave(buffer.getChannelData(0), buffer.getChannelData(1));
    } else {
      result = buffer.getChannelData(0);
    }

    return encodeWAV(result, format, sampleRate, numChannels, bitDepth);
  }

  function interleave(inputL, inputR) {
    var length = inputL.length + inputR.length;
    var result = new Float32Array(length);

    var index = 0;
    var inputIndex = 0;

    while (index < length) {
      result[index++] = inputL[inputIndex];
      result[index++] = inputR[inputIndex];
      inputIndex++;
    }
    return result;
  }

  function encodeWAV(samples, format, sampleRate, numChannels, bitDepth) {
    var bytesPerSample = bitDepth / 8;
    var blockAlign = numChannels * bytesPerSample;

    var buffer = new ArrayBuffer(44 + samples.length * bytesPerSample);
    var view = new DataView(buffer);

    /* RIFF identifier */
    writeString(view, 0, "RIFF");
    /* RIFF chunk length */
    view.setUint32(4, 36 + samples.length * bytesPerSample, true);
    /* RIFF type */
    writeString(view, 8, "WAVE");
    /* format chunk identifier */
    writeString(view, 12, "fmt ");
    /* format chunk length */
    view.setUint32(16, 16, true);
    /* sample format (raw) */
    view.setUint16(20, format, true);
    /* channel count */
    view.setUint16(22, numChannels, true);
    /* sample rate */
    view.setUint32(24, sampleRate, true);
    /* byte rate (sample rate * block align) */
    view.setUint32(28, sampleRate * blockAlign, true);
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, blockAlign, true);
    /* bits per sample */
    view.setUint16(34, bitDepth, true);
    /* data chunk identifier */
    writeString(view, 36, "data");
    /* data chunk length */
    view.setUint32(40, samples.length * bytesPerSample, true);
    if (format === 1) {
      // Raw PCM
      floatTo16BitPCM(view, 44, samples);
    } else {
      writeFloat32(view, 44, samples);
    }

    return buffer;
  }

  function writeFloat32(output, offset, input) {
    for (var i = 0; i < input.length; i++, offset += 4) {
      output.setFloat32(offset, input[i], true);
    }
  }

  function floatTo16BitPCM(output, offset, input) {
    for (var i = 0; i < input.length; i++, offset += 2) {
      var s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
  }

  function writeString(view, offset, string) {
    for (var i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  const handleNextLine = () => {
    if (lessonData && currentLineIndex < lessonData.translation.length - 1) {
      setCurrentLineIndex(currentLineIndex + 1);
      setCompletedLines(completedLines + 1);
      setRecorded(false);
      setScore(null);
      setAudioBlob(null);
      setWordScores([]);
      setWordsToPractice([]);
      // setFeedback("");
    } else if (currentLineIndex === lessonData.translation.length - 1) {
      setCompletedLines(lessonData.translation.length);
    }
  };

  const progressPercentage = lessonData
    ? (completedLines / lessonData.translation.length) * 120
    : 0;

  const handleReRecord = () => {
    setRecorded(false);
    setScore(null);
    setAudioBlob(null);
    setWordScores([]);
    setWordsToPractice([]);
    // setFeedback("");
    startRecording();
  };

  const getWordColor = (score) => {
    if (score >= 85) return "green.500";
    if (score >= 60) return "yellow.500";
    return "red.500";
  };

  const toggleActivityCompletion = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${config.API_URL}/activities/${lessonId}/pronunciation/toggle`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCompleted(response.data.completed);
      toast({
        title: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error toggling activity completion:", error);
      toast({
        title: "Error",
        description: "Failed to update activity status. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <Center>Loading lesson data...</Center>;
  }

  if (error) {
    return <Center color="red.500">{error}</Center>;
  }

  if (!lessonData) {
    return <Center>No lesson data available.</Center>;
  }

  return (
    <Box maxWidth="1050px" margin="auto" padding={8}>
      <BackToLessonLink />
      <VStack spacing={6} align="stretch">
        <HStack justifyContent="space-between" flexWrap="wrap">
          <Heading size="lg">
            Lesson {lessonId} Activity: Pronunciation Practice
          </Heading>
          <Badge
            colorScheme={completed ? "green" : "gray"}
            p={2}
            borderRadius="md"
          >
            {completed ? "Completed" : "Not completed"}
          </Badge>
        </HStack>
        <Text>
          <strong>Listen</strong> to the native speaker, then{" "}
          <strong>record</strong> yourself saying the phrase. Our{" "}
          <strong>AI tutor</strong> will analyze your pronunciation and provide
          instant feedback on <strong>accuracy</strong>,{" "}
          <strong>fluency</strong>, and <strong>completeness</strong>.
        </Text>
        <Text mt={2}>
          <strong>Focus</strong> on words marked in yellow or red, aiming for an
          overall score of <strong>80% or higher</strong>, and remember that
          consistent practice leads to natural-sounding Italian.
        </Text>

        <Progress value={progressPercentage} colorScheme="blue" size="sm" />

        <Box borderWidth={1} borderRadius="lg" p={4}>
          <Flex
            justifyContent="center"
            alignItems="center"
            minHeight="150px"
            height="100%"
            px={4}
          >
            <Button
              leftIcon={<Icon as={FaPlay} />}
              onClick={playNativeAudio}
              mb={4}
              colorScheme="gray"
              size="lg"
              width="100%"
              height="auto"
              whiteSpace="normal"
              py={4}
            >
              {lessonData.translation[currentLineIndex].italian}
            </Button>
          </Flex>

          {isGrading ? (
            <Center>
              <Spinner size="xl" />
              <Text ml={4}>Grading your pronunciation...</Text>
            </Center>
          ) : recorded ? (
            <VStack spacing={4} align="stretch">
              <HStack justifyContent="space-between">
                <Button
                  leftIcon={<Icon as={FaPlay} />}
                  onClick={playRecordedAudio}
                >
                  Play Your Voice
                </Button>
                <Button
                  leftIcon={<Icon as={FaMicrophone} />}
                  onClick={handleReRecord}
                >
                  Re-record
                </Button>
              </HStack>

              <Box borderWidth={1} borderRadius="md" p={4} bg="gray.50">
                {/* <Heading size="md" mb={2}>
                  Overall Assessment
                </Heading> */}
                <Text fontSize="2xl" fontWeight="bold" color="blue.600" mb={2}>
                  Pronunciation Score: {score ? score.toFixed(1) : "N/A"}%
                </Text>
                <Text>
                  <strong>Accuracy Score:</strong>{" "}
                  {accuracyScore ? accuracyScore.toFixed(1) : "N/A"}%
                </Text>
                <Text>
                  <strong>Fluency Score:</strong>{" "}
                  {fluencyScore ? fluencyScore.toFixed(1) : "N/A"}%
                </Text>
                <Text>
                  <strong>Completeness Score:</strong>{" "}
                  {completenessScore ? completenessScore.toFixed(1) : "N/A"}%
                </Text>
              </Box>

              <Box>
                <Heading size="sm" mb={2}>
                  Word-by-Word Assessment
                </Heading>
                <Flex flexWrap="wrap" gap={2}>
                  {wordScores.map((wordScore, index) => (
                    <Badge
                      key={index}
                      bg={getWordColor(wordScore.score)}
                      color="white"
                      p={2}
                      borderRadius="md"
                    >
                      {wordScore.word}
                    </Badge>
                  ))}
                </Flex>
              </Box>

              {wordsToPractice.length > 0 && (
                <Box>
                  <Heading size="sm" mb={2}>
                    Words to Practice
                  </Heading>
                  <VStack align="stretch" spacing={2}>
                    {wordsToPractice.map((word, index) => (
                      <HStack
                        key={index}
                        justifyContent="space-between"
                        p={2}
                        bg="gray.100"
                        borderRadius="md"
                      >
                        <Text>{word.word}</Text>
                        <Text fontWeight="bold">{word.score.toFixed(1)}%</Text>
                      </HStack>
                    ))}
                  </VStack>
                </Box>
              )}
            </VStack>
          ) : (
            <Flex justifyContent="center">
              <Button
                onClick={isRecording ? stopRecording : startRecording}
                colorScheme={isRecording ? "red" : "blue"}
                leftIcon={<Icon as={isRecording ? FaStop : FaMicrophone} />}
                mt={4}
              >
                {isRecording ? "Stop Recording" : "Start Recording"}
              </Button>
            </Flex>
          )}
        </Box>

        <HStack justifyContent="space-between" flexWrap="wrap" gap={4}>
          <Button
            rightIcon={<Icon as={FaArrowRight} />}
            onClick={handleNextLine}
            colorScheme="green"
            isDisabled={
              !recorded ||
              currentLineIndex === lessonData.translation.length - 1
            }
          >
            Next Sentence
          </Button>

          <Button
            rightIcon={<CheckIcon />}
            colorScheme={completed ? "green" : "gray"}
            onClick={toggleActivityCompletion}
          >
            {completed ? "Mark as Incomplete" : "Mark Activity as Complete"}
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}

export default PronunciationActivity;
