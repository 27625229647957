import React from "react";
import YouTube from "react-youtube";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from "@chakra-ui/react";

const HowToPracticeModal = ({ isOpen, onClose }) => {
  // YouTube video options
  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  // YouTube video ID
  const videoId = "wRM20UH7XN4";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>How to Practice a Lesson</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box width="100%">
            <YouTube videoId={videoId} opts={opts} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={onClose}>
            Got it!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default HowToPracticeModal;
