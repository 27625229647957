import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import config from "../../config";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
} from "@chakra-ui/react";

const UserProfile = ({ user: initialUser, setUser }) => {
  const [name, setName] = useState(initialUser?.name || "");
  const [email, setEmail] = useState(initialUser?.email || "");
  const [password, setPassword] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [localUser, setLocalUser] = useState(initialUser);
  const [alert, setAlert] = useState(null);
  const toast = useToast();

  const handleStripePortalAccess = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${config.API_URL}/create-customer-portal-session`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error accessing Stripe portal:", error);
      toast({
        title: "Error",
        description: "Unable to access Stripe portal. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchUserData = useCallback(async () => {
    if (!initialUser) {
      try {
        setIsLoading(true);
        const token = localStorage.getItem("token");
        const response = await axios.get(`${config.API_URL}/user-profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setLocalUser(response.data);
        setUser(response.data);
        setName(response.data.name);
        setEmail(response.data.email);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setAlert({
          status: "error",
          title: "Error fetching user data",
          description: error.response?.data?.message || "An error occurred",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [initialUser, setUser]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const updateData = { name, email };
      if (password) {
        updateData.password = password;
      }
      const response = await axios.put(
        `${config.API_URL}/update-profile`,
        updateData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setLocalUser(response.data.user);
      setUser(response.data.user);
      setIsEditing(false);
      setPassword("");
      setAlert({
        status: "success",
        title: "Profile updated",
        description: "Your profile has been successfully updated.",
      });
    } catch (error) {
      setAlert({
        status: "error",
        title: "Update failed",
        description:
          error.response?.data?.message ||
          "An error occurred while updating the profile",
      });
    }
  };

  if (isLoading) return <Box>Loading...</Box>;

  return (
    <Box maxWidth="600px" margin="auto" mt={8} px={4}>
      <VStack spacing={6} align="stretch">
        <Heading>User Profile</Heading>
        {alert && (
          <Alert status={alert.status}>
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>{alert.title}</AlertTitle>
              <AlertDescription display="block">
                {alert.description}
              </AlertDescription>
            </Box>
          </Alert>
        )}
        {isEditing ? (
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>New Password (leave blank to keep current)</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter new password"
              />
            </FormControl>
            <Button type="submit" colorScheme="blue" mt={4}>
              Save Changes
            </Button>
            <Button
              onClick={() => {
                setIsEditing(false);
                setPassword("");
                setAlert(null);
              }}
              ml={2}
              mt={4}
            >
              Cancel
            </Button>
          </form>
        ) : (
          <>
            <Text>
              <strong>Name:</strong> {localUser.name}
            </Text>
            <Text>
              <strong>Email:</strong> {localUser.email}
            </Text>
            <Text>
              <strong>Subscription Status:</strong>{" "}
              {localUser.subscription_status}
            </Text>
            <Text>
              <strong>Days Practiced:</strong> {localUser.days_practiced}
            </Text>
            <Text>
              <strong>Activities Completed:</strong>{" "}
              {localUser.activities_completed}
            </Text>
            <Text>
              <strong>Lessons Completed:</strong> {localUser.lessons_completed}
            </Text>
            <Button
              onClick={() => {
                setIsEditing(true);
                setAlert(null);
              }}
              colorScheme="blue"
              mr={2}
            >
              Edit Profile
            </Button>
            {localUser.subscription_status !== "unsubscribed" && (
              <Button onClick={handleStripePortalAccess} colorScheme="green">
                Manage Subscription
              </Button>
            )}
          </>
        )}
      </VStack>
    </Box>
  );
};

export default UserProfile;
