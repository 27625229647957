import React, { useState } from "react";
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormErrorMessage,
} from "@chakra-ui/react";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = ({ onLogin }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (values, { setSubmitting }) => {
    setShowAlert(false);

    try {
      const response = await axios.post(`${config.API_URL}/login`, values);
      onLogin(response.data.token, response.data.user);
      const from = location.state?.from?.pathname || "/";
      navigate(from, { replace: true });
    } catch (error) {
      setAlertTitle("Login failed");
      setAlertDescription(error.response?.data?.message || "An error occurred");
      setShowAlert(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box maxWidth="420px" margin="auto" mt={8} px={4}>
      <VStack spacing={4} align="stretch">
        <Heading>Login to ItalianChat</Heading>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Field name="email">
                {({ field }) => (
                  <FormControl isInvalid={errors.email && touched.email}>
                    <FormLabel>Email</FormLabel>
                    <Input {...field} type="email" />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <FormControl
                    isInvalid={errors.password && touched.password}
                    mt={4}
                  >
                    <FormLabel>Password</FormLabel>
                    <Input {...field} type="password" />
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {showAlert && (
                <Alert status="error" mt={4} borderRadius="md">
                  <AlertIcon />
                  <Box flex="1">
                    <AlertTitle>{alertTitle}</AlertTitle>
                    <AlertDescription display="block">
                      {alertDescription}
                    </AlertDescription>
                  </Box>
                </Alert>
              )}

              <Button
                type="submit"
                colorScheme="green"
                width="full"
                mt={4}
                isLoading={isSubmitting}
              >
                Login
              </Button>
            </Form>
          )}
        </Formik>
        <Text mt={4}>
          Don't have an account? <RouterLink to="/register">Sign up</RouterLink>
        </Text>
        <Text>
          <RouterLink as={RouterLink} to="/request-password-reset">
            Forgot your password?
          </RouterLink>
        </Text>
      </VStack>
    </Box>
  );
};

export default Login;
