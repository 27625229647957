import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  FormErrorMessage,
} from "@chakra-ui/react";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("error");
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    setShowAlert(false);

    try {
      const response = await axios.post(
        `${config.API_URL}/reset-password/${token}`,
        { password: values.password }
      );
      setAlertMessage(response.data.message);
      setAlertStatus("success");
      setShowAlert(true);
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      setAlertMessage(error.response?.data?.message || "An error occurred");
      setAlertStatus("error");
      setShowAlert(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box maxWidth="420px" margin="auto" mt={8} px={4}>
      <VStack spacing={4} align="stretch">
        <Heading>Reset Password</Heading>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={ResetPasswordSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Field name="password">
                {({ field }) => (
                  <FormControl isInvalid={errors.password && touched.password}>
                    <FormLabel>New Password</FormLabel>
                    <Input {...field} type="password" />
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="confirmPassword">
                {({ field }) => (
                  <FormControl
                    isInvalid={
                      errors.confirmPassword && touched.confirmPassword
                    }
                    mt={4}
                  >
                    <FormLabel>Confirm New Password</FormLabel>
                    <Input {...field} type="password" />
                    <FormErrorMessage>
                      {errors.confirmPassword}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {showAlert && (
                <Alert status={alertStatus} mt={4}>
                  <AlertIcon />
                  {alertMessage}
                </Alert>
              )}

              <Button
                type="submit"
                colorScheme="green"
                width="full"
                mt={4}
                isLoading={isSubmitting}
              >
                Reset Password
              </Button>
            </Form>
          )}
        </Formik>
      </VStack>
    </Box>
  );
};

export default ResetPassword;
