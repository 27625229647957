import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Button,
  Switch,
  IconButton,
  Badge,
  Divider,
  Grid,
  GridItem,
  useBreakpointValue,
  Icon,
  Center,
  Flex,
  useToast,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import { CheckIcon, AddIcon } from "@chakra-ui/icons";
import { FaPlay, FaPause, FaForward, FaBackward } from "react-icons/fa";
import BackToLessonLink from "../../components/BackToLessonLink";

// CloudFront distribution URL
const CLOUDFRONT_URL = "https://damdyhcq5ngiz.cloudfront.net";

function ReadListenActivity() {
  const { lessonId } = useParams();
  const [showEnglish, setShowEnglish] = useState(false);
  const [showItalian, setShowItalian] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPlayingNormal, setIsPlayingNormal] = useState(false);
  const [isPlayingSlow, setIsPlayingSlow] = useState(false);
  const [hasStartedNormal, setHasStartedNormal] = useState(false);
  const [hasStartedSlow, setHasStartedSlow] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const lineAudioRef = useRef(new Audio());
  const normalAudioRef = useRef(new Audio());
  const slowAudioRef = useRef(new Audio());
  const [addedPhrases, setAddedPhrases] = useState(() => {
    const stored = localStorage.getItem(`addedPhrases_${lessonId}`);
    return stored ? new Set(JSON.parse(stored)) : new Set();
  });
  const toast = useToast();

  const fetchLessonData = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const [lessonResponse, progressResponse] = await Promise.all([
        axios.get(`${config.API_URL}/lessons/${lessonId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.API_URL}/user/progress`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setLessonData(lessonResponse.data);
      const activityProgress = progressResponse.data.activities_progress.find(
        (progress) =>
          progress.lesson_id === parseInt(lessonId) &&
          progress.activity_type === "reading-listening"
      );
      setCompleted(activityProgress ? activityProgress.completed : false);
      setIsLoading(false);
    } catch (err) {
      setError("Failed to fetch lesson data. Please try again later.");
      setIsLoading(false);
    }
  }, [lessonId]);

  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  useEffect(() => {
    if (lessonData) {
      normalAudioRef.current.src = lessonData.audio_url;
      slowAudioRef.current.src = lessonData.audio_slow_url;
    }
  }, [lessonData]);

  useEffect(() => {
    const lineAudio = lineAudioRef.current;
    const normalAudio = normalAudioRef.current;
    const slowAudio = slowAudioRef.current;

    const handleLineEnded = () => setCurrentlyPlaying(null);
    const handleNormalEnded = () => {
      setIsPlayingNormal(false);
      setHasStartedNormal(false);
    };
    const handleSlowEnded = () => {
      setIsPlayingSlow(false);
      setHasStartedSlow(false);
    };

    lineAudio.addEventListener("ended", handleLineEnded);
    normalAudio.addEventListener("ended", handleNormalEnded);
    slowAudio.addEventListener("ended", handleSlowEnded);

    return () => {
      lineAudio.removeEventListener("ended", handleLineEnded);
      normalAudio.removeEventListener("ended", handleNormalEnded);
      slowAudio.removeEventListener("ended", handleSlowEnded);
    };
  }, []);

  const playFullConversation = (speed) => {
    const audio =
      speed === "normal" ? normalAudioRef.current : slowAudioRef.current;
    const isPlaying = speed === "normal" ? isPlayingNormal : isPlayingSlow;
    const setIsPlaying =
      speed === "normal" ? setIsPlayingNormal : setIsPlayingSlow;
    const setHasStarted =
      speed === "normal" ? setHasStartedNormal : setHasStartedSlow;

    if (isPlaying) {
      audio.pause();
    } else {
      lineAudioRef.current.pause();
      setCurrentlyPlaying(null);
      if (speed === "normal") {
        slowAudioRef.current.pause();
        setIsPlayingSlow(false);
      } else {
        normalAudioRef.current.pause();
        setIsPlayingNormal(false);
      }
      audio.currentTime = 0;
      audio.play().catch((e) => console.error("Error playing audio:", e));
    }
    setIsPlaying(!isPlaying);
    setHasStarted(true);
  };

  const playSentenceAudio = (lineIndex) => {
    const audio = lineAudioRef.current;

    if (currentlyPlaying === lineIndex) {
      audio.pause();
      setCurrentlyPlaying(null);
    } else {
      normalAudioRef.current.pause();
      slowAudioRef.current.pause();
      setIsPlayingNormal(false);
      setIsPlayingSlow(false);
      audio.src = `${CLOUDFRONT_URL}/lesson_${lessonData.lesson_number}_${lineIndex}.mp3`;
      audio
        .play()
        .catch((error) => console.error("Error playing audio:", error));
      setCurrentlyPlaying(lineIndex);
    }
  };

  const getFullConversationButtonText = (speed) => {
    const hasStarted = speed === "normal" ? hasStartedNormal : hasStartedSlow;
    const isPlaying = speed === "normal" ? isPlayingNormal : isPlayingSlow;
    if (!hasStarted) {
      return speed === "normal"
        ? "Regular Speed Conversation"
        : "Slow Speed Conversation";
    }
    return isPlaying ? "Pause" : "Play";
  };

  const toggleActivityCompletion = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${config.API_URL}/activities/${lessonId}/reading-listening/toggle`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCompleted(response.data.completed);
      toast({
        title: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error toggling activity completion:", error);
      toast({
        title: "Error",
        description: "Failed to update activity status. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    localStorage.setItem(
      `addedPhrases_${lessonId}`,
      JSON.stringify([...addedPhrases])
    );
  }, [addedPhrases, lessonId]);

  const addToPhaseBank = async (line, index) => {
    if (addedPhrases.has(index)) {
      toast({
        title: "Phrase already added",
        description: "This phrase is already in your Phrase Bank.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.post(
        `${config.API_URL}/phrase-bank`,
        {
          lesson_id: lessonId,
          italian: line.italian,
          english: line.english,
          audio_url: `${CLOUDFRONT_URL}/lesson_${lessonData.lesson_number}_${index}.mp3`,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setAddedPhrases((prev) => new Set(prev).add(index));
      toast({
        title: "Phrase added to Phrase Bank",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Phrase already exists in the database
        setAddedPhrases((prev) => new Set(prev).add(index));
        toast({
          title: "Phrase already exists",
          description: "This phrase is already in your Phrase Bank.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error("Error adding phrase to Phrase Bank:", error);
        toast({
          title: "Error adding phrase",
          description: "Failed to add phrase to Phrase Bank. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  // Use responsive layout
  const gridTemplateColumns = useBreakpointValue({
    base: "auto 1fr", // Mobile: icon and text in one column
    md: "auto 1fr 1fr", // Desktop: separate columns for icon, Italian, and English
  });

  if (isLoading) return <Center>Loading lesson data...</Center>;
  if (error) return <Center color="red.500">{error}</Center>;
  if (!lessonData) return <Center>No lesson data available.</Center>;

  return (
    <Box maxWidth="1050px" margin="auto" padding={8}>
      <BackToLessonLink />
      <VStack spacing={6} align="stretch">
        <HStack justifyContent="space-between" flexWrap="wrap">
          <Heading size="lg">Lesson {lessonId} Activity: Read & Listen</Heading>
          <Badge
            colorScheme={completed ? "green" : "gray"}
            p={2}
            borderRadius="md"
          >
            {completed ? "Completed" : "Not completed"}
          </Badge>
        </HStack>

        <Text>
          Listen to each sentence of the dialogue and{" "}
          <strong>follow along</strong> with the text.{" "}
          <strong>Repeat after each sentence</strong> to practice your
          pronunciation. Try to{" "}
          <strong>
            understand the conversation without looking at the English
            translation
          </strong>
          .
        </Text>

        <VStack align="start" spacing={2}>
          <Text fontWeight="bold">Toggle visibility:</Text>
          <HStack spacing={4}>
            <HStack>
              <Text>Italian</Text>
              <Switch
                isChecked={showItalian}
                onChange={() => setShowItalian(!showItalian)}
              />
            </HStack>
            <HStack>
              <Text>English</Text>
              <Switch
                isChecked={showEnglish}
                onChange={() => setShowEnglish(!showEnglish)}
              />
            </HStack>
          </HStack>
          <Text fontSize="md" color="gray.700">
            Use these toggles to show or hide the Italian and English text. This
            can help you focus on listening or challenge your comprehension.
          </Text>
        </VStack>

        <Flex
          direction={{ base: "column", md: "row" }}
          align="center"
          bg="gray.50"
          p={3}
          borderRadius="md"
          mb={4}
        >
          <HStack spacing={2} mb={{ base: 2, md: 0 }}>
            <Text fontWeight="bold" fontSize="md" color="gray.700">
              Note:
            </Text>
            <Text fontSize="md" color="gray.700">
              Use the
            </Text>
            <Box
              bg="#EDF2F7"
              // color="white"
              borderRadius="full"
              w="24px"
              h="24px"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              fontWeight="bold"
            >
              <AddIcon boxSize={4} color="gray.700" />
            </Box>
            <Text fontSize="md" color="gray.700">
              icon to add phrases to your
            </Text>
          </HStack>
          <Link
            as={RouterLink}
            to="/phrase-bank"
            color="blue.500"
            fontWeight="bold"
            textDecoration="underline"
            ml={{ base: 0, md: 2 }}
          >
            Phrase Bank
          </Link>
        </Flex>

        <VStack align="stretch" spacing={2} mb={8}>
          {lessonData.translation.map((line, index) => (
            <Grid
              key={index}
              templateColumns={gridTemplateColumns}
              gap={4}
              bg={index % 2 === 0 ? "gray.50" : "gray.100"}
              p={2}
              borderRadius="md"
              alignItems="center"
            >
              <GridItem>
                <IconButton
                  icon={
                    <Icon as={currentlyPlaying === index ? FaPause : FaPlay} />
                  }
                  onClick={() => playSentenceAudio(index)}
                  size="sm"
                  colorScheme="blue"
                  aria-label={`${
                    currentlyPlaying === index ? "Pause" : "Play"
                  } audio for ${line.italian}`}
                />
              </GridItem>
              <GridItem>
                <VStack align="stretch" spacing={2}>
                  <Text
                    fontWeight="bold"
                    cursor="pointer"
                    onClick={() => playSentenceAudio(index)}
                    filter={showItalian ? "none" : "blur(4px)"}
                    transition="filter 0.3s"
                  >
                    {line.italian}
                  </Text>
                  <Text
                    filter={showEnglish ? "none" : "blur(4px)"}
                    transition="filter 0.3s"
                    display={{ base: "block", md: "none" }} // Show on mobile, hide on desktop
                  >
                    {line.english}
                  </Text>
                </VStack>
              </GridItem>
              <GridItem display={{ base: "none", md: "block" }}>
                {" "}
                {/* Hide on mobile, show on desktop */}
                <Text
                  filter={showEnglish ? "none" : "blur(4px)"}
                  transition="filter 0.3s"
                >
                  {line.english}
                </Text>
              </GridItem>
              <GridItem>
                <Tooltip
                  label={
                    addedPhrases.has(index)
                      ? "Added to Phrase Bank"
                      : "Add to Phrase Bank"
                  }
                >
                  <IconButton
                    icon={addedPhrases.has(index) ? <CheckIcon /> : <AddIcon />}
                    onClick={() => addToPhaseBank(line, index)}
                    size="sm"
                    colorScheme={addedPhrases.has(index) ? "green" : "gray"}
                    aria-label={
                      addedPhrases.has(index)
                        ? "Added to Phrase Bank"
                        : "Add to Phrase Bank"
                    }
                    isDisabled={addedPhrases.has(index)}
                  />
                </Tooltip>
              </GridItem>
            </Grid>
          ))}
        </VStack>

        {/* <Divider /> */}

        <VStack spacing={4} align="center">
          <Text>Choose the speed to play the full conversation:</Text>
          <Flex
            direction={{ base: "column", md: "row" }}
            gap={4}
            width="100%"
            justify="center"
          >
            <Button
              leftIcon={<Icon as={isPlayingNormal ? FaPause : FaPlay} />}
              rightIcon={<Icon as={FaForward} />}
              onClick={() => playFullConversation("normal")}
              colorScheme="blue"
              width={{ base: "100%", md: "auto" }}
            >
              {getFullConversationButtonText("normal")}
            </Button>
            <Button
              leftIcon={<Icon as={isPlayingSlow ? FaPause : FaPlay} />}
              rightIcon={<Icon as={FaBackward} />}
              onClick={() => playFullConversation("slow")}
              colorScheme="green"
              width={{ base: "100%", md: "auto" }}
            >
              {getFullConversationButtonText("slow")}
            </Button>
          </Flex>
          <Divider my={6} borderWidth="2px" borderColor="gray.200" />
          <Button
            rightIcon={<CheckIcon />}
            colorScheme={completed ? "green" : "gray"}
            onClick={toggleActivityCompletion}
          >
            {completed ? "Mark as Incomplete" : "Mark Activity as Complete"}
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
}

export default ReadListenActivity;
