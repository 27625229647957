import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Icon } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";

const BackToDashboardLink = () => {
  return (
    <Link
      as={RouterLink}
      to={`/`}
      display="inline-flex"
      alignItems="center"
      mb={4}
      color="blue.500"
      fontWeight="medium"
    >
      <Icon as={ChevronLeftIcon} mr={1} />
      Back to Dashboard
    </Link>
  );
};

export default BackToDashboardLink;
