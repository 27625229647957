import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";

const RiskFreeModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Our Risk-Free Guarantee</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Your satisfaction is our top priority. Here's our commitment to you:
          </Text>
          <Text mt={4}>
            1. Enjoy a 3-day free trial with full access to all features.
          </Text>
          <Text mt={2}>
            2. After your trial, if you choose to continue, you're protected by
            our 30-day money-back guarantee.
          </Text>
          <Text mt={2}>
            3. Not satisfied? Simply contact us within 30 days of your purchase
            for a full refund, no questions asked.
          </Text>
          <Text mt={4}>
            With ItalianChat, you can learn with confidence, knowing you have
            nothing to lose and a new language to gain.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RiskFreeModal;
