import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import config from "./config";
import { Box, VStack } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Lesson from "./pages/Lesson";
import ReadListenActivity from "./pages/activities/ReadListenActivity";
import LessonQuizActivity from "./pages/activities/LessonQuizActivity";
import PronunciationActivity from "./pages/activities/PronunciationActivity";
import TranslationActivity from "./pages/activities/TranslationActivity";
import LiveChatActivity from "./pages/activities/LiveChatActivity";
import Register from "./pages/users/Register";
import EmailVerification from "./pages/users/EmailVerification";
import PlanSelection from "./pages/users/PlanSelection";
import Login from "./pages/users/Login";
import UserProfile from "./pages/users/UserProfile";
import RequestPasswordReset from "./pages/users/RequestPasswordReset";
import ResetPassword from "./pages/users/ResetPassword";
import ChatbotTutor from "./pages/chatbot/ChatbotTutor";
import PhraseBank from "./pages/phrase_bank/PhraseBank";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuthStatus = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.get(`${config.API_URL}/verify-token`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setIsAuthenticated(true);
        setUser(response.data.user);
      } catch (error) {
        console.error("Token verification failed:", error);
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        setUser(null);
      }
    } else {
      setIsAuthenticated(false);
      setUser(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const login = (token, user) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    setIsAuthenticated(true);
    setUser(user);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsAuthenticated(false);
    setUser(null);
  };

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Router>
      <AuthWrapper checkAuthStatus={checkAuthStatus}>
        <VStack minHeight="100vh" spacing={0}>
          <Navbar
            isAuthenticated={isAuthenticated}
            user={user}
            onLogout={logout}
          />
          <Box flex={1} width="100%">
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route
                path="/verify-email/:token"
                element={<EmailVerification />}
              />
              <Route
                path="/plan-selection"
                element={
                  <PlanSelection
                    isAuthenticated={isAuthenticated}
                    user={user}
                    setUser={setUser}
                  />
                }
              />
              <Route path="/login" element={<Login onLogin={login} />} />
              <Route
                path="/request-password-reset"
                element={<RequestPasswordReset />}
              />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} user={user}>
                    <Home user={user} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lessons/:lessonId"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} user={user}>
                    <Lesson />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lessons/:lessonId/reading-listening"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} user={user}>
                    <ReadListenActivity />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lessons/:lessonId/translation"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} user={user}>
                    <TranslationActivity />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lessons/:lessonId/pronunciation"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} user={user}>
                    <PronunciationActivity />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lessons/:lessonId/lesson-quiz"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} user={user}>
                    <LessonQuizActivity />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lessons/:lessonId/live-chat"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} user={user}>
                    <LiveChatActivity />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} user={user}>
                    <UserProfile user={user} setUser={setUser} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/chatbot-tutor"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} user={user}>
                    <ChatbotTutor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/phrase-bank"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} user={user}>
                    <PhraseBank />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Box>
          <Footer />
        </VStack>
      </AuthWrapper>
    </Router>
  );
}

function AuthWrapper({ children, checkAuthStatus }) {
  const location = useLocation();

  useEffect(() => {
    const lastChecked = localStorage.getItem("lastAuthCheck");
    const now = new Date().getTime();

    if (!lastChecked || now - parseInt(lastChecked) > 5 * 60 * 1000) {
      checkAuthStatus();
      localStorage.setItem("lastAuthCheck", now.toString());
    }
  }, [location.pathname, checkAuthStatus]);

  return <>{children}</>;
}

function ProtectedRoute({ children, isAuthenticated, user }) {
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!user || !user.is_subscription_active) {
    return <Navigate to="/plan-selection" />;
  }
  return children;
}

export default App;
