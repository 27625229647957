import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { handleUnauthorized } from "../../utils/authUtils";
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Input,
  IconButton,
  useToast,
  Spinner,
  Avatar,
  Flex,
} from "@chakra-ui/react";
import { FaMicrophone, FaStop, FaPaperPlane } from "react-icons/fa";
import { MdGTranslate } from "react-icons/md";

const ChatbotTutor = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [chatId, setChatId] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const chatInitializedRef = useRef(false);
  const chatContainerRef = useRef(null); // New ref for the chat container
  const toast = useToast();
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isTutorTyping, setIsTutorTyping] = useState(false);
  const [isStartingChat, setIsStartingChat] = useState(true);
  const navigate = useNavigate();

  // Function to scroll to the bottom of the chat
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  // Use useEffect to scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const startChat = useCallback(async () => {
    try {
      setIsStartingChat(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(`${config.API_URL}/start-chat`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setChatId(response.data.chat_id);
      setMessages([{ role: "assistant", content: response.data.message }]);
      playAudio(response.data.audio);
    } catch (error) {
      console.error("Error starting chat:", error);
      handleUnauthorized(error, navigate);
      toast({
        title: "Error",
        description: "Failed to start chat. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsStartingChat(false);
    }
  }, [toast, navigate]);

  const endChat = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${config.API_URL}/end-chat`,
        { chat_id: chatId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.error("Error ending chat:", error);
    }
  }, [chatId]);

  useEffect(() => {
    if (!chatInitializedRef.current) {
      startChat();
      chatInitializedRef.current = true;
    }

    return () => {
      if (chatId) {
        endChat();
      }
    };
  }, [startChat, chatId, endChat]);

  const sendMessage = async (type) => {
    if ((type === "text" && !inputText) || (type === "speech" && !audioBlob))
      return;

    setIsTranscribing(type === "speech");

    try {
      let data;
      if (type === "text") {
        data = { input: inputText, type, chat_id: chatId };
      } else {
        const reader = new FileReader();
        reader.readAsArrayBuffer(audioBlob);
        const audioArrayBuffer = await new Promise((resolve, reject) => {
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

        const base64Audio = btoa(
          new Uint8Array(audioArrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        data = {
          input: base64Audio,
          type,
          chat_id: chatId,
        };
      }

      const token = localStorage.getItem("token");
      const sendResponse = await axios.post(
        `${config.API_URL}/send-message`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Add user's message to the chat
      setMessages((prev) => [
        ...prev,
        {
          id: Date.now(),
          role: "user",
          content: sendResponse.data.user_message,
        },
      ]);

      setIsTranscribing(false);
      setIsTutorTyping(true);

      // Get tutor's response
      const tutorResponse = await axios.get(
        `${config.API_URL}/get-tutor-response`,
        {
          params: { chat_id: chatId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMessages((prev) => [
        ...prev,
        {
          id: Date.now(),
          role: "assistant",
          content: tutorResponse.data.message,
          translation: tutorResponse.data.translation,
          showTranslation: false,
        },
      ]);
      playAudio(tutorResponse.data.audio);
    } catch (error) {
      console.error("Error in chat process:", error);
      handleUnauthorized(error, navigate);
      toast({
        title: "Error",
        description: "Failed to process message. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setInputText("");
      setAudioBlob(null);
      setIsTranscribing(false);
      setIsTutorTyping(false);

      // Clear the MediaRecorder
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
        mediaRecorderRef.current = null;
      }
    }
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const options = {
          mimeType: "audio/webm;codecs=opus",
        };

        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.log(`${options.mimeType} is not supported`);
          options.mimeType = "audio/ogg;codecs=opus";

          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.log(`${options.mimeType} is not supported`);
            options.mimeType = "";
          }
        }

        mediaRecorderRef.current = new MediaRecorder(stream, options);
        audioChunksRef.current = [];

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/webm;codecs=opus",
          });
          setAudioBlob(audioBlob);
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        toast({
          title: "Error",
          description:
            "Failed to access microphone. Please check your browser settings.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const playAudio = (audioContent) => {
    const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
    audio.play().catch((error) => console.error("Error playing audio:", error));
  };

  const TutorHeader = () => (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      bg="blue.100"
      p={2}
      borderTopRadius="md"
    >
      <HStack>
        <Avatar name="Sofia" src="/lang-tutor-25x25.png" size="sm" />
        <Text fontWeight="bold">Sofia</Text>
      </HStack>
    </Flex>
  );

  if (isStartingChat) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="400px"
      >
        <Spinner size="xl" />
        <Text ml={4}>Starting chat...</Text>
      </Box>
    );
  }

  return (
    <Box maxWidth="720px" margin="auto" padding={8}>
      <VStack spacing={4} align="stretch">
        <Box borderWidth={1} borderRadius="md" overflow="hidden">
          <TutorHeader />
          <Box
            ref={chatContainerRef} // Add ref to the chat container
            height="400px"
            overflowY="auto"
            padding={4}
          >
            {messages.map((message, index) => (
              <Box
                key={index}
                bg={message.role === "assistant" ? "blue.100" : "green.100"}
                padding={2}
                borderRadius="md"
                marginY={2}
              >
                <HStack justifyContent="space-between">
                  <Text>{message.content}</Text>
                  {message.role === "assistant" && message.translation && (
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => {
                        setMessages(
                          messages.map((msg, i) =>
                            i === index
                              ? {
                                  ...msg,
                                  showTranslation: !msg.showTranslation,
                                }
                              : msg
                          )
                        );
                      }}
                      aria-label="Toggle translation"
                      rightIcon={<MdGTranslate />}
                      p={1}
                    >
                      <Text fontSize="xs" color="gray.500">
                        Translate
                      </Text>
                    </Button>
                  )}
                </HStack>
                {message.role === "assistant" &&
                  message.showTranslation &&
                  message.translation && (
                    <Text fontSize="sm" color="gray.600" mt={1}>
                      {message.translation}
                    </Text>
                  )}
              </Box>
            ))}
            {isTranscribing && (
              <Box bg="green.100" padding={2} borderRadius="md" marginY={2}>
                <Spinner size="sm" />
              </Box>
            )}
            {isTutorTyping && (
              <Box bg="blue.100" padding={2} borderRadius="md" marginY={2}>
                <Spinner size="sm" />
              </Box>
            )}
          </Box>
        </Box>
        <HStack>
          <Input
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Type your message..."
          />
          <IconButton
            icon={<FaPaperPlane />}
            onClick={() => sendMessage("text")}
            aria-label="Send message"
          />
        </HStack>
        <HStack>
          <Button
            leftIcon={isRecording ? <FaStop /> : <FaMicrophone />}
            onClick={isRecording ? stopRecording : startRecording}
            colorScheme={isRecording ? "red" : "blue"}
          >
            {isRecording ? "Stop Recording" : "Start Recording"}
          </Button>
          {audioBlob && (
            <Button onClick={() => sendMessage("speech")} colorScheme="green">
              Send Audio
            </Button>
          )}
        </HStack>
        <Box mt={4} p={4} bg="gray.50" borderRadius="md">
          <Text>
            <strong>Getting Started with Sofia:</strong> Begin your Italian
            conversation by suggesting a topic or choosing from 'greetings',
            'travel phrases', or let Sofia guide the discussion - this helps
            tailor the chat to your learning needs and comfort level.
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default ChatbotTutor;
