import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormErrorMessage,
  useDisclosure,
  Link,
  SimpleGrid,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { FaLock, FaShieldAlt, FaUsers, FaStar } from "react-icons/fa";
import RiskFreeModal from "../../components/RiskFreeModal";

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

const Register = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState("info");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const navigate = useNavigate(); // use if check your email page is implemented

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setShowAlert(false);

    try {
      const response = await axios.post(`${config.API_URL}/register`, values);

      // Set success alert
      setAlertStatus("success");
      setAlertTitle("Registration successful");
      setAlertDescription(response.data.message);
      setShowAlert(true);

      // Clear form fields
      resetForm();

      // Optionally, redirect to a "Check your email" page
      // setTimeout(() => navigate("/check-email"), 3000);
    } catch (error) {
      // Set error alert
      setAlertStatus("error");
      setAlertTitle("Registration failed");
      setAlertDescription(error.response?.data?.message || "An error occurred");
      setShowAlert(true);
    } finally {
      setSubmitting(false);
    }
  };

  // Then add this inside the Register component before the return statement
  useEffect(() => {
    // Add Rewardful tracking to the form
    const formElement = document.querySelector('form');
    if (formElement && window.Rewardful) {
      formElement.setAttribute('data-rewardful', '');
    }
  }, []);

  return (
    <Box maxWidth="700px" margin="auto" mt={8} px={4}>
      <VStack spacing={6} align="stretch">
        <Box textAlign="center">
          <Heading as="h1" fontSize="3xl" my={2}>
            Create your account and unlock fluent Italian conversations in just
            10 minutes a day.
          </Heading>
          <Text fontSize="2xl" color="gray.600">
            Start with a 3-day free trial. Cancel anytime.
          </Text>
          <Text fontSize="2xl" color="gray.600">
            <Link color="blue.500" onClick={onOpen}>
              <InfoOutlineIcon /> Zero-risk guarantee.
            </Link>
          </Text>
        </Box>
        <Formik
          initialValues={{ name: "", email: "", password: "" }}
          validationSchema={RegisterSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Field name="name">
                {({ field }) => (
                  <FormControl isInvalid={errors.name && touched.name}>
                    <FormLabel>Name</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="email">
                {({ field }) => (
                  <FormControl isInvalid={errors.email && touched.email} mt={4}>
                    <FormLabel>Email</FormLabel>
                    <Input {...field} type="email" />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <FormControl
                    isInvalid={errors.password && touched.password}
                    mt={4}
                  >
                    <FormLabel>Password</FormLabel>
                    <Input {...field} type="password" />
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {showAlert && (
                <Alert status={alertStatus} mt={4} borderRadius="md">
                  <AlertIcon />
                  <Box flex="1">
                    <AlertTitle>{alertTitle}</AlertTitle>
                    <AlertDescription display="block">
                      {alertDescription}
                    </AlertDescription>
                  </Box>
                </Alert>
              )}

              <Button
                type="submit"
                colorScheme="green"
                width="full"
                mt={4}
                isLoading={isSubmitting}
              >
                Create Account & Get 3-Day Free Trial
              </Button>
            </Form>
          )}
        </Formik>
        <Text mt={4}>
          Already have an account? <RouterLink to="/login">Log in</RouterLink>
        </Text>
      </VStack>
      <Box maxWidth="1000px" margin="0 auto" mt={12} pt={12}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
          <VStack>
            <Icon as={FaLock} w={10} h={10} color="green.500" />
            <Text fontWeight="bold" fontSize="lg">
              Secure Payment
            </Text>
            <Text textAlign="center">
              Your transactions are safe and encrypted
            </Text>
          </VStack>
          <VStack>
            <Icon as={FaShieldAlt} w={10} h={10} color="blue.500" />
            <Text fontWeight="bold" fontSize="lg">
              Risk-Free Guarantee
            </Text>
            <Text textAlign="center">
              30-day money-back guarantee, no questions asked
            </Text>
          </VStack>
          <VStack>
            <Icon as={FaUsers} w={10} h={10} color="purple.500" />
            <Text fontWeight="bold" fontSize="lg">
              Join 1000s of Happy Students
            </Text>
            <Text textAlign="center">
              Be part of our growing community of Italian learners
            </Text>
          </VStack>
        </SimpleGrid>
      </Box>
      <Box
        maxWidth="1000px"
        margin="0 auto"
        mt={16}
        textAlign="center"
        bg="gray.50"
        p={8}
        borderRadius="lg"
        boxShadow="md"
      >
        <VStack spacing={4}>
          <HStack spacing={1}>
            {[...Array(5)].map((_, i) => (
              <Icon key={i} as={FaStar} w={6} h={6} color="yellow.400" />
            ))}
          </HStack>
          <Text fontSize="lg" fontStyle="italic">
            "The repetition practice section is fantastic. It helps me reinforce
            what I've learned and practice until I get it right. ItalianChat has
            truly improved my fluency."
          </Text>
          <Text fontWeight="bold">- Mark R., Frequent Traveler</Text>
        </VStack>
      </Box>
      <RiskFreeModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default Register;
