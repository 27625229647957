import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  useToast,
  useDisclosure,
  Link,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import {
  FaCheckCircle,
  FaLock,
  FaShieldAlt,
  FaUsers,
  FaStar,
} from "react-icons/fa";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import RiskFreeModal from "../../components/RiskFreeModal";
import HowToPracticeModal from "../../components/HowToPracticeModal";

const PlanSelection = ({ isAuthenticated, user, setUser }) => {
  const {
    isOpen: isRiskFreeOpen,
    onOpen: onRiskFreeOpen,
    onClose: onRiskFreeClose,
  } = useDisclosure();
  const {
    isOpen: isHowToPracticeOpen,
    onOpen: onHowToPracticeOpen,
    onClose: onHowToPracticeClose,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const selectPlan = async (plan) => {
    if (!isAuthenticated) {
      // Redirect to register page if not logged in
      navigate("/register");
      return;
    }

    if (
      user &&
      (user.subscription_status === "trial" ||
        user.subscription_status === "trialing" ||
        user.subscription_status === "active")
    ) {
      // Redirect to profile page if user has an active or trial subscription
      navigate("/profile");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${config.API_URL}/create-checkout-session`,
        { plan },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Redirect to Stripe Checkout
      window.location.href = response.data.checkoutUrl;
    } catch (error) {
      console.error("Error creating checkout session:", error);
      toast({
        title: "Error",
        description:
          "There was an error processing your request. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  function PriceWrapper({ children }) {
    return (
      <Box
        mb={4}
        shadow="base"
        borderWidth="1px"
        alignSelf={{ base: "center", lg: "flex-start" }}
        borderColor={useColorModeValue("gray.200", "gray.500")}
        borderRadius={"xl"}
        w={{ lg: "412px", md: "475px", sm: "475px" }}
        mx={4}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box py={12}>
      <VStack spacing={2} textAlign="center" maxW="800px" mx="auto">
        <Heading as="h1" fontSize="4xl" mx={4}>
          Speak Italian with Confidence in Just 10 Minutes a Day: Choose Your
          Plan
        </Heading>
        <Text fontSize="2xl" color={"gray.600"} mx={4}>
          Start with a 3-day free trial.{" "}
          <Link color="blue.500" onClick={onRiskFreeOpen}>
            Zero-risk guarantee.
          </Link>{" "}
          Cancel at anytime.
        </Text>
        <Text fontSize="2xl" color={"gray.600"} mx={4}>
          Is ItalianChat right for you?{" "}
          <Link color="blue.500" onClick={onHowToPracticeOpen}>
            <ArrowForwardIcon /> See it in action.
          </Link>{" "}
        </Text>
      </VStack>
      <Stack
        direction={{ base: "column", lg: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
        maxWidth="1000px"
        margin="0 auto"
      >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Monthly Plan
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                19
              </Text>
              <Text as="sup" fontSize="xl" fontWeight="900">
                .95
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={6}>
              <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                <ListIcon
                  as={FaCheckCircle}
                  color="green.500"
                  mt={1}
                  mr={2}
                  flexShrink={0}
                />
                Real-life Italian conversations for everyday situations
              </ListItem>
              <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                <ListIcon
                  as={FaCheckCircle}
                  color="green.500"
                  mt={1}
                  mr={2}
                  flexShrink={0}
                />
                Live AI tutor for personalized practice anytime
              </ListItem>
              <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                <ListIcon
                  as={FaCheckCircle}
                  color="green.500"
                  mt={1}
                  mr={2}
                  flexShrink={0}
                />
                Instant pronunciation feedback to perfect your accent
              </ListItem>
              <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                <ListIcon
                  as={FaCheckCircle}
                  color="green.500"
                  mt={1}
                  mr={2}
                  flexShrink={0}
                />
                Interactive audio flashcards for rapid vocabulary building
              </ListItem>
              <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                <ListIcon
                  as={FaCheckCircle}
                  color="green.500"
                  mt={1}
                  mr={2}
                  flexShrink={0}
                />
                Guided lesson paths to ensure steady progress
              </ListItem>
              <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                <ListIcon
                  as={FaCheckCircle}
                  color="green.500"
                  mt={1}
                  mr={2}
                  flexShrink={0}
                />
                Native speaker audio at both slow and normal speeds
              </ListItem>
              <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                <ListIcon
                  as={FaCheckCircle}
                  color="green.500"
                  mt={1}
                  mr={2}
                  flexShrink={0}
                />
                Track your learning journey with progress metrics
              </ListItem>
              <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                <ListIcon
                  as={FaCheckCircle}
                  color="green.500"
                  mt={1}
                  mr={2}
                  flexShrink={0}
                />
                Access to all content on any device, anytime
              </ListItem>
              <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                <ListIcon
                  as={FaCheckCircle}
                  color="green.500"
                  mt={1}
                  mr={2}
                  flexShrink={0}
                />
                New conversations and topics added regularly
              </ListItem>
            </List>
            <Box w="90%" pt={7}>
              <Button
                w="full"
                colorScheme="blue"
                onClick={() => selectPlan("monthly")}
                size="lg"
              >
                Start 3-Day Free Trial
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: "translate(-50%)" }}
            >
              <Text
                textTransform="uppercase"
                bg={useColorModeValue("green.300", "green.700")}
                px={3}
                py={1}
                color={useColorModeValue("gray.900", "gray.300")}
                fontSize="md"
                fontWeight="600"
                rounded="xl"
              >
                GET 3 MONTHS FREE!
              </Text>
            </Box>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Yearly Plan
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  179
                </Text>
                <Text as="sup" fontSize="xl" fontWeight="900">
                  .95
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /year
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List spacing={3} textAlign="start" px={6}>
                <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                  <ListIcon
                    as={FaCheckCircle}
                    color="green.500"
                    mt={1}
                    mr={2}
                    flexShrink={0}
                  />
                  Real-life Italian conversations for everyday situations
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                  <ListIcon
                    as={FaCheckCircle}
                    color="green.500"
                    mt={1}
                    mr={2}
                    flexShrink={0}
                  />
                  Live AI tutor for personalized practice anytime
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                  <ListIcon
                    as={FaCheckCircle}
                    color="green.500"
                    mt={1}
                    mr={2}
                    flexShrink={0}
                  />
                  Instant pronunciation feedback to perfect your accent
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                  <ListIcon
                    as={FaCheckCircle}
                    color="green.500"
                    mt={1}
                    mr={2}
                    flexShrink={0}
                  />
                  Interactive audio flashcards for rapid vocabulary building
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                  <ListIcon
                    as={FaCheckCircle}
                    color="green.500"
                    mt={1}
                    mr={2}
                    flexShrink={0}
                  />
                  Guided lesson paths to ensure steady progress
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                  <ListIcon
                    as={FaCheckCircle}
                    color="green.500"
                    mt={1}
                    mr={2}
                    flexShrink={0}
                  />
                  Native speaker audio at both slow and normal speeds
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                  <ListIcon
                    as={FaCheckCircle}
                    color="green.500"
                    mt={1}
                    mr={2}
                    flexShrink={0}
                  />
                  Track your learning journey with progress metrics
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                  <ListIcon
                    as={FaCheckCircle}
                    color="green.500"
                    mt={1}
                    mr={2}
                    flexShrink={0}
                  />
                  Access to all content on any device, anytime
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" fontSize="lg">
                  <ListIcon
                    as={FaCheckCircle}
                    color="green.500"
                    mt={1}
                    mr={2}
                    flexShrink={0}
                  />
                  New conversations and topics added regularly
                </ListItem>
              </List>
              <Box w="90%" pt={7}>
                <Button
                  w="full"
                  colorScheme="blue"
                  onClick={() => selectPlan("yearly")}
                  size="lg"
                >
                  Start 3-Day Free Trial
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
      </Stack>

      <Box maxWidth="1000px" margin="0 auto" mt={12}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
          <VStack>
            <Icon as={FaLock} w={10} h={10} color="green.500" />
            <Text fontWeight="bold" fontSize="lg">
              Secure Payment
            </Text>
            <Text textAlign="center">
              Your transactions are safe and encrypted
            </Text>
          </VStack>
          <VStack>
            <Icon as={FaShieldAlt} w={10} h={10} color="blue.500" />
            <Text fontWeight="bold" fontSize="lg">
              Risk-Free Guarantee
            </Text>
            <Text textAlign="center">
              30-day money-back guarantee, no questions asked
            </Text>
          </VStack>
          <VStack>
            <Icon as={FaUsers} w={10} h={10} color="purple.500" />
            <Text fontWeight="bold" fontSize="lg">
              Join 1000s of Happy Students
            </Text>
            <Text textAlign="center">
              Be part of our growing community of Italian learners
            </Text>
          </VStack>
        </SimpleGrid>
      </Box>
      <Box
        maxWidth="1000px"
        margin="0 auto"
        mt={16}
        textAlign="center"
        bg="gray.50"
        p={8}
        borderRadius="lg"
        boxShadow="md"
      >
        <VStack spacing={4}>
          <HStack spacing={1}>
            {[...Array(5)].map((_, i) => (
              <Icon key={i} as={FaStar} w={6} h={6} color="yellow.400" />
            ))}
          </HStack>
          <Text fontSize="lg" fontStyle="italic">
            "The repetition practice section is fantastic. It helps me reinforce
            what I've learned and practice until I get it right. ItalianChat has
            truly improved my fluency."
          </Text>
          <Text fontWeight="bold">- Mark R., Frequent Traveler</Text>
        </VStack>
      </Box>
      <RiskFreeModal isOpen={isRiskFreeOpen} onClose={onRiskFreeClose} />
      <HowToPracticeModal
        isOpen={isHowToPracticeOpen}
        onClose={onHowToPracticeClose}
      />
    </Box>
  );
};

export default PlanSelection;
