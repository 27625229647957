import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  HStack,
  Link,
  Badge,
  Icon,
  Center,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { FaPlay, FaPause, FaBackward } from "react-icons/fa";
import { CheckIcon } from "@chakra-ui/icons";
import { useWavesurfer } from "@wavesurfer/react";
import BackToLessonLink from "../../components/BackToLessonLink";

function LiveChatActivity() {
  const { lessonId } = useParams();
  const containerRef = useRef(null);
  const [completed, setCompleted] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  const fetchLessonData = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const [lessonResponse, progressResponse] = await Promise.all([
        axios.get(`${config.API_URL}/lessons/${lessonId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.API_URL}/user/progress`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setLessonData(lessonResponse.data);
      const activityProgress = progressResponse.data.activities_progress.find(
        (progress) =>
          progress.lesson_id === parseInt(lessonId) &&
          progress.activity_type === "live-chat"
      );
      setCompleted(activityProgress ? activityProgress.completed : false);
      setIsLoading(false);
    } catch (err) {
      setError("Failed to fetch lesson data. Please try again later.");
      setIsLoading(false);
    }
  }, [lessonId]);

  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  // Use the useWavesurfer hook
  const { wavesurfer, isReady, isPlaying } = useWavesurfer({
    container: containerRef,
    url: lessonData ? lessonData.audio_with_spaces_url : null,
    waveColor: "rgb(200, 0, 200)",
    progressColor: "rgb(100, 0, 100)",
    cursorColor: "rgb(100, 0, 100)",
    height: 100,
    barWidth: 2,
    barGap: 1,
  });

  // Function to toggle play/pause
  const togglePlayPause = () => {
    if (wavesurfer) {
      wavesurfer.playPause();
    }
  };

  // Function to go back 3 seconds
  const goBackThreeSeconds = () => {
    if (wavesurfer) {
      const currentTime = wavesurfer.getCurrentTime();
      const newTime = Math.max(0, currentTime - 3);
      wavesurfer.setTime(newTime);
    }
  };

  const toggleActivityCompletion = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${config.API_URL}/activities/${lessonId}/live-chat/toggle`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCompleted(response.data.completed);
      toast({
        title: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error toggling activity completion:", error);
      toast({
        title: "Error",
        description: "Failed to update activity status. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <Center>Loading lesson data...</Center>;
  }

  if (error) {
    return <Center color="red.500">{error}</Center>;
  }

  if (!lessonData) {
    return <Center>No lesson data available.</Center>;
  }

  return (
    <Box maxWidth="800px" margin="auto" padding={8}>
      <BackToLessonLink />
      <VStack spacing={6} align="stretch">
        <HStack justifyContent="space-between" flexWrap="wrap">
          <Heading size="lg">
            Lesson {lessonId} Activity: Live Chat Simulation
          </Heading>
          <Badge
            colorScheme={completed ? "green" : "gray"}
            p={2}
            borderRadius="md"
            alignSelf="flex-start"
          >
            {completed ? "Completed" : "Not completed"}
          </Badge>
        </HStack>

        <Text>
          Simulate a <strong>real Italian conversation</strong> by listening and
          filling in the blanks. Repeat the phrases you hear to practice your
          speaking skills in context.
        </Text>

        {/* Wavesurfer container */}
        <Box
          ref={containerRef}
          borderWidth={1}
          borderRadius="lg"
          overflow="hidden"
        />

        <HStack spacing={4} justify="center">
          <Button
            leftIcon={<Icon as={isPlaying ? FaPause : FaPlay} />}
            onClick={togglePlayPause}
            colorScheme="blue"
            isDisabled={!isReady}
          >
            {isPlaying ? "Pause" : "Play"}
          </Button>
          <Button
            leftIcon={<Icon as={FaBackward} />}
            onClick={goBackThreeSeconds}
            isDisabled={!isReady}
          >
            Back 3s
          </Button>
        </HStack>

        <Text py={4} textAlign="center">
          Ready for the next level?{" "}
          <Link as={RouterLink} color="blue.500" to="/chatbot-tutor">
            <strong>Take a Live Chat</strong>{" "}
          </Link>{" "}
          with Your AI Tutor
        </Text>

        <Flex justifyContent="center" mt={8}>
          <Button
            rightIcon={<CheckIcon />}
            colorScheme={completed ? "green" : "gray"}
            onClick={toggleActivityCompletion}
          >
            {completed ? "Mark as Incomplete" : "Mark Activity as Complete"}
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
}

export default LiveChatActivity;
