import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  HStack,
  Icon,
  Flex,
  Badge,
  Center,
  useToast,
} from "@chakra-ui/react";
import { FaPlay, FaArrowRight } from "react-icons/fa";
import { CheckIcon } from "@chakra-ui/icons";
import BackToLessonLink from "../../components/BackToLessonLink";

// CloudFront distribution URL
const CLOUDFRONT_URL = "https://damdyhcq5ngiz.cloudfront.net";

function TranslationActivity() {
  const { lessonId } = useParams();
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showItalian, setShowItalian] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const audioRef = useRef(new Audio());
  const toast = useToast();

  const fetchLessonData = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const [lessonResponse, progressResponse] = await Promise.all([
        axios.get(`${config.API_URL}/lessons/${lessonId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.API_URL}/user/progress`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setLessonData(lessonResponse.data);
      const activityProgress = progressResponse.data.activities_progress.find(
        (progress) =>
          progress.lesson_id === parseInt(lessonId) &&
          progress.activity_type === "translation"
      );
      setCompleted(activityProgress ? activityProgress.completed : false);
      setIsLoading(false);
    } catch (err) {
      setError("Failed to fetch lesson data. Please try again later.");
      setIsLoading(false);
    }
  }, [lessonId]);

  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  useEffect(() => {
    if (showItalian && lessonData) {
      audioRef.current.src = `${CLOUDFRONT_URL}/lesson_${lessonData.lesson_number}_${currentCardIndex}.mp3`;
    }
  }, [showItalian, currentCardIndex, lessonData]);

  const playAudio = (e) => {
    e.stopPropagation();
    audioRef.current
      .play()
      .catch((error) => console.error("Error playing audio:", error));
  };

  const handleNextCard = () => {
    if (lessonData && currentCardIndex < lessonData.translation.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      setShowItalian(false);
    }
  };

  const handlePreviousCard = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
      setShowItalian(false);
    }
  };

  const toggleLanguage = () => {
    setShowItalian(!showItalian);
  };

  const toggleActivityCompletion = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${config.API_URL}/activities/${lessonId}/translation/toggle`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCompleted(response.data.completed);
      toast({
        title: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error toggling activity completion:", error);
      toast({
        title: "Error",
        description: "Failed to update activity status. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <Center>Loading lesson data...</Center>;
  }

  if (error) {
    return <Center color="red.500">{error}</Center>;
  }

  if (!lessonData) {
    return <Center>No lesson data available.</Center>;
  }

  return (
    <Box maxWidth="1050px" margin="auto" padding={8}>
      <BackToLessonLink />
      <VStack spacing={6} align="stretch">
        <HStack justifyContent="space-between" flexWrap="wrap">
          <Heading size="lg">Lesson {lessonId} Activity: Translation</Heading>
          <Badge
            colorScheme={completed ? "green" : "gray"}
            p={2}
            borderRadius="md"
          >
            {completed ? "Completed" : "Not completed"}
          </Badge>
        </HStack>

        <Text>
          Translate the English phrase to Italian, then{" "}
          <strong>click/tap the card to flip</strong> and check your answer.
          This activity enhances your <strong>listening comprehension</strong>{" "}
          and <strong>quick translation skills</strong>.
        </Text>
        <Text mt={2}>
          <strong>Pro Tip:</strong> Toggle between English and Italian
          repeatedly for maximum learning benefit.
        </Text>

        <Flex
          borderWidth={1}
          borderRadius="lg"
          p={4}
          height="200px"
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
          onClick={toggleLanguage}
          bg="blue.50"
        >
          <VStack>
            <Text fontSize="2xl" fontWeight="bold">
              {showItalian
                ? lessonData.translation[currentCardIndex].italian
                : lessonData.translation[currentCardIndex].english}
            </Text>
            {showItalian && (
              <Button
                leftIcon={<Icon as={FaPlay} />}
                onClick={playAudio}
                size="sm"
                mt={2}
              >
                Play Audio
              </Button>
            )}
          </VStack>
        </Flex>

        <Text textAlign="center">
          Card {currentCardIndex + 1} of {lessonData.translation.length}
        </Text>

        <HStack justifyContent="space-between">
          <Button
            onClick={handlePreviousCard}
            disabled={currentCardIndex === 0}
          >
            Previous
          </Button>
          <Button
            onClick={handleNextCard}
            rightIcon={<Icon as={FaArrowRight} />}
            colorScheme="blue"
            disabled={currentCardIndex === lessonData.translation.length - 1}
          >
            Next
          </Button>
        </HStack>

        <Flex justifyContent="center" mt={8}>
          <Button
            rightIcon={<CheckIcon />}
            colorScheme={completed ? "green" : "gray"}
            onClick={toggleActivityCompletion}
          >
            {completed ? "Mark as Incomplete" : "Mark Activity as Complete"}
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
}

export default TranslationActivity;
