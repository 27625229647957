import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  RadioGroup,
  Radio,
  Badge,
  Center,
  Flex,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import BackToLessonLink from "../../components/BackToLessonLink";

function LessonQuizActivity() {
  const { lessonId } = useParams();
  const [quizData, setQuizData] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const toast = useToast();

  const fetchLessonData = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const [lessonResponse, progressResponse] = await Promise.all([
        axios.get(`${config.API_URL}/quizzes/${lessonId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.API_URL}/user/progress`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setQuizData(lessonResponse.data);
      const activityProgress = progressResponse.data.activities_progress.find(
        (progress) =>
          progress.lesson_id === parseInt(lessonId) &&
          progress.activity_type === "lesson-quiz"
      );
      setCompleted(activityProgress ? activityProgress.completed : false);
      setIsLoading(false);
    } catch (err) {
      setError("Failed to fetch lesson data. Please try again later.");
      setIsLoading(false);
    }
  }, [lessonId]);

  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  // Function to handle answer submission
  const handleSubmit = () => {
    if (!selectedAnswer) {
      setShowWarning(true);
      return;
    }

    setShowWarning(false);
    const currentQuestionData = quizData.questions[currentQuestion];
    const isAnswerCorrect = selectedAnswer === currentQuestionData.correct;
    setIsCorrect(isAnswerCorrect);
    setShowFeedback(true);

    if (isAnswerCorrect) {
      setScore(score + 1);
    }
  };

  // Function to move to the next question
  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizData.questions.length) {
      setCurrentQuestion(nextQuestion);
      setSelectedAnswer("");
      setShowFeedback(false);
      setShowWarning(false);
    } else {
      setShowScore(true);
    }
  };

  // Function to reset the quiz
  const resetQuiz = () => {
    setCurrentQuestion(0);
    setSelectedAnswer("");
    setScore(0);
    setShowScore(false);
    setShowFeedback(false);
    setShowWarning(false);
  };

  const toggleActivityCompletion = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${config.API_URL}/activities/${lessonId}/lesson-quiz/toggle`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCompleted(response.data.completed);
      toast({
        title: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error toggling activity completion:", error);
      toast({
        title: "Error",
        description: "Failed to update activity status. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <Center>Loading quiz data...</Center>;
  }

  if (error) {
    return <Center color="red.500">{error}</Center>;
  }

  if (!quizData) {
    return <Center>No quiz data available.</Center>;
  }

  return (
    <Box maxWidth="540px" margin="auto" padding={8}>
      <BackToLessonLink />
      <VStack spacing={6} align="stretch">
        <HStack justifyContent="space-between" flexWrap="wrap">
          <Heading size="lg">Lesson {lessonId} Activity: Quiz</Heading>
          <Badge
            colorScheme={completed ? "green" : "gray"}
            p={2}
            borderRadius="md"
            alignSelf="flex-start"
          >
            {completed ? "Completed" : "Not completed"}
          </Badge>
        </HStack>
        <Text py={2}>
          Test your understanding of the dialogue and reinforce key Italian
          phrases with this quick, interactive quiz.
        </Text>

        {showScore ? (
          <VStack spacing={4} align="stretch">
            <Text fontSize="xl" fontWeight="bold">
              You scored {score} out of {quizData.questions.length}
            </Text>
            <Button onClick={resetQuiz} colorScheme="blue">
              Retake Quiz
            </Button>
          </VStack>
        ) : (
          <VStack spacing={4} align="stretch">
            <Text fontSize="xl" fontWeight="bold">
              Question {currentQuestion + 1}/{quizData.questions.length}
            </Text>
            <Text>{quizData.questions[currentQuestion].question}</Text>
            <RadioGroup onChange={setSelectedAnswer} value={selectedAnswer}>
              <VStack align="stretch">
                {quizData.questions[currentQuestion].options.map((option) => (
                  <Radio key={option.id} value={option.id}>
                    {option.text}
                  </Radio>
                ))}
              </VStack>
            </RadioGroup>
            {showWarning && (
              <Alert status="warning" variant="subtle" borderRadius="md">
                <AlertIcon />
                <AlertDescription>
                  Please select an answer before submitting.
                </AlertDescription>
              </Alert>
            )}
            {!showFeedback ? (
              <Button onClick={handleSubmit} colorScheme="orange">
                Submit
              </Button>
            ) : (
              <VStack spacing={4} align="stretch">
                <Alert
                  status={isCorrect ? "success" : "error"}
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  borderRadius="md"
                >
                  <AlertIcon boxSize="40px" mr={0} />
                  <AlertTitle mt={4} mb={1} fontSize="lg">
                    {isCorrect ? "Correct!" : "Incorrect"}
                  </AlertTitle>
                  <AlertDescription maxWidth="sm">
                    {isCorrect
                      ? "Great job! You got it right."
                      : `The correct answer was: ${
                          quizData.questions[currentQuestion].options.find(
                            (option) =>
                              option.id ===
                              quizData.questions[currentQuestion].correct
                          ).text
                        }`}
                  </AlertDescription>
                </Alert>
                <Button onClick={handleNextQuestion} colorScheme="blue">
                  Next Question
                </Button>
              </VStack>
            )}
          </VStack>
        )}

        <Flex justifyContent="center" mt={8}>
          <Button
            rightIcon={<CheckIcon />}
            colorScheme={completed ? "green" : "gray"}
            onClick={toggleActivityCompletion}
          >
            {completed ? "Mark as Incomplete" : "Mark Activity as Complete"}
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
}

export default LessonQuizActivity;
