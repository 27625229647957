import React from "react";
import {
  Box,
  Flex,
  HStack,
  VStack,
  Link,
  Button,
  Image,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Container,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";

const Navbar = ({ isAuthenticated, user, onLogout }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const NavLinks = () => (
    <>
      <Link as={RouterLink} to="/" onClick={onClose}>
        Lessons
      </Link>
      {(!isAuthenticated ||
        (user &&
          user.subscription_status !== "trial" &&
          user.subscription_status !== "trialing" &&
          user.subscription_status !== "active")) && (
        <>
          <Link as={RouterLink} to="/plan-selection" onClick={onClose}>
            Pricing
          </Link>
          {/* <Link
            as={RouterLink}
            to="https://help.learnitalianpod.com/"
            target="_blank"
          >
            How to Use
          </Link> */}
        </>
      )}

      {isAuthenticated ? (
        <>
          <Link as={RouterLink} to="/phrase-bank" onClick={onClose}>
            Phrase Bank
          </Link>
          <Link as={RouterLink} to="/chatbot-tutor" onClick={onClose}>
            Live Tutor
          </Link>
          <Link
            as={RouterLink}
            to="https://help.learnitalianpod.com/"
            target="_blank"
            onClick={onClose}
          >
            Help Center
          </Link>
          <Link as={RouterLink} to="/profile" onClick={onClose}>
            Profile
          </Link>
          <Link
            as={RouterLink}
            onClick={() => {
              onClose();
              onLogout();
            }}
          >
            Logout
          </Link>
        </>
      ) : (
        <Link as={RouterLink} to="/login" onClick={onClose}>
          Sign In
        </Link>
      )}
    </>
  );

  return (
    <Box
      bg="#F0F6FF"
      boxShadow="md"
      position="sticky"
      top="0"
      zIndex="sticky"
      width="100%"
    >
      <Container maxWidth="1200px" px={4}>
        <Flex
          h={16}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Flex alignItems="center">
            <RouterLink to="/">
              <Image src="/logo120x120.png" alt="ItalianChat Logo" h="52px" />
            </RouterLink>
            <RouterLink to="/">
              <Text fontSize="2xl" fontWeight="bold" ml={2} color="gray.700">
                ItalianChat
              </Text>
            </RouterLink>
          </Flex>

          <HStack
            spacing={8}
            alignItems="center"
            display={{ base: "none", md: "flex" }}
          >
            <HStack as="nav" spacing={4}>
              <NavLinks />
            </HStack>
            {!isAuthenticated && (
              <Button as={RouterLink} to="/register" colorScheme="green">
                Sign Up
              </Button>
            )}
          </HStack>

          <IconButton
            size="md"
            icon={<HamburgerIcon />}
            aria-label="Open Menu"
            display={{ md: "none" }}
            onClick={onOpen}
          />
        </Flex>
      </Container>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="stretch">
                <NavLinks />
                {!isAuthenticated && (
                  <Button
                    as={RouterLink}
                    to="/register"
                    colorScheme="green"
                    onClick={onClose}
                  >
                    Sign Up
                  </Button>
                )}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Navbar;
